/* src/components/Header.css */

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    max-width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .header-left {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
  
  .header-logo {
    width: 50px;
    height: 50px;
    margin-right: 12px;
  }
  
  .header-text {
    display: flex;
    flex-direction: column;
  }
  
  .header-title {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .header-subtitle {
    font-size: 0.9rem;
    margin: 2px 0 0;
    color: #666;
  }
  
  .catalog-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .catalog-button:hover {
    background-color: #0056b3;
  }
  
  /* Smaller screen adjustments */
  @media (max-width: 768px) {
    .header {
      padding: 8px 12px;
      flex-direction: column;
      align-items: center;
    }
  
    .header-logo {
      width: 40px;
      height: 40px;
      margin: 0;
    }
  
    .header-title {
      font-size: 1.25rem;
    }
  
    .header-subtitle {
      font-size: 0.8rem;
    }
  
    .catalog-button {
      padding: 6px 12px;
      font-size: 0.9rem;
      margin-top: 6px;
    }
  }
  