/* src/components/Home.css */
.home-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .mission-section,
  .about-section {
    margin-bottom: 30px;
  }
  
  .mission-section h2,
  .about-section h2 {
    font-size: 2rem;
    color: #333;
  }
  
  .mission-section p,
  .about-section p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
  }
  
  .about-content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .about-content-right {
    flex-direction: row; /* Image appears to the right */
  }
  
  .about-content-left {
    flex-direction: row; /* Image appears to the left */
  }
  
  .about-image {
    width: 40%;
    height: auto;
    border-radius: 8px;
    margin: 0 15px;
  }
  
  .catalog-button {
    display: block;
    width: 100%;
    max-width: 300px;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: auto;
    text-align: center;
    text-decoration: none;
  }
  
  .catalog-button:hover {
    background-color: #0056b3;
  }  
  