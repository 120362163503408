.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* For hover effects on buttons */
button:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}

/* Truncates the description to one line */
.truncate-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Allows multiline display with respect for line breaks */
.multiline {
  white-space: pre-line; /* Respects line breaks and wraps text on multiple lines */
}

.header {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9; /* Optional: add background color */
  border-bottom: 1px solid #ddd; /* Optional: add bottom border */
}

.header-logo {
  width: 50px; /* Small logo size */
  height: 50px;
  margin-right: 15px;
  border-radius: 5px; /* Optional: round the corners */
}

.header-text {
  display: flex;
  flex-direction: column;
}

.header-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.header-subtitle {
  font-size: 1rem;
  color: #666; /* Subtle color for subtitle */
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
