/* src/components/Catalog.css */

.controls {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .controls input, .controls select {
    font-size: 1.1rem;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    min-width: 180px;
  }
  
  .controls input:focus, .controls select:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .sort-section {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .sort-section label {
    font-weight: bold;
    font-size: 1.1rem;
  }
  